import React, { useState, useEffect, Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { useRouter } from "next/router";
import { twJoin, twMerge } from "tailwind-merge";

import TransitionLink from "components/TransitionLink";

import Pump from "assets/brand/logos/pump.svg";

const navigation = [
  { name: "Services", href: "/services" },
  { name: "Projects", href: "/projects" },
  { name: "Team", href: "/team" },
  { name: "Careers", href: "/careers" },
  { name: "Blog", href: "/blog" },
];

const navigationMobile = [
  ...navigation,
  {
    name: "Contact",
    href: "/contact",
  },
];

const HamburgerButtonAndMenu = ({ hamburgerWhite }: { hamburgerWhite: boolean }) => {
  const router = useRouter();
  const [startingRoute] = useState(router.pathname);

  const [open, setOpen] = useState(false);

  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const rotationWrapperRef = React.useRef<HTMLSpanElement>(null);
  const line1Ref = React.useRef<HTMLSpanElement>(null);
  const line2Ref = React.useRef<HTMLSpanElement>(null);
  const animationsRef = React.useRef<{ rotation: Animation; line1: Animation; line2: Animation }>();

  // Attach animations on the hamburger elements
  useEffect(() => {
    const rotationWrapper = rotationWrapperRef.current;
    const line1 = line1Ref.current;
    const line2 = line2Ref.current;
    if (!rotationWrapper || !line1 || !line2) return;

    const animationOptions: KeyframeAnimationOptions = {
      duration: 500,
      easing: "ease-in-out",
      fill: "both",
    };

    const rotationAnimation = rotationWrapper.animate(
      [
        { offset: 0, transform: "none" },
        { offset: 0.33, transform: "none" },
        { offset: 0.66, transform: "rotate(90deg)" },
        { offset: 1, transform: "rotate(90deg)" },
      ],
      animationOptions
    );
    const line1Animation = line1.animate(
      [
        { offset: 0, transform: "translateY(6px)" },
        { offset: 0.33, transform: "none" },
        { offset: 0.66, transform: "none" },
        { offset: 1, transform: "translateY(8px)" },
      ],
      animationOptions
    );
    const line2Animation = line2.animate(
      [
        { offset: 0, transform: "translateY(-6px)" },
        { offset: 0.33, transform: "none" },
        { offset: 0.66, transform: "none" },
        { offset: 1, transform: "translateY(-8px)" },
      ],
      animationOptions
    );

    // Animations must not autoplay at mount
    rotationAnimation.pause();
    line1Animation.pause();
    line2Animation.pause();

    animationsRef.current = {
      rotation: rotationAnimation,
      line1: line1Animation,
      line2: line2Animation,
    };

    return () => {
      rotationAnimation.cancel();
      line1Animation.cancel();
      line2Animation.cancel();
    };
  }, []);

  const setOpenAndResetAnimation = (open: boolean) => {
    setOpen(open);

    const animations = animationsRef.current;
    if (!animations) return;

    // Reverse animation when closing the menu
    const playbackRate = open ? 1 : -1;
    // Reset animation starting point
    const currentTime = open ? 0 : -1;

    animations.rotation.currentTime = currentTime;
    animations.line1.currentTime = currentTime;
    animations.line2.currentTime = currentTime;

    animations.rotation.playbackRate = playbackRate;
    animations.line1.playbackRate = playbackRate;
    animations.line2.playbackRate = playbackRate;
  };

  const setOpenAndAnimate = (open: boolean) => {
    setOpenAndResetAnimation(open);

    animationsRef.current?.rotation.play();
    animationsRef.current?.line1.play();
    animationsRef.current?.line2.play();
  };

  // If menu is open and the route changes, close the menu; it doesn't happen
  // automatically unfortunately
  useEffect(() => {
    // The first run of this effect is not a route change, so skip it
    if (router.pathname === startingRoute) return;
    setOpenAndResetAnimation(false);
  }, [router.pathname, startingRoute]);

  useEffect(() => {
    // Close the menu if after a resize the hambruger button is not visible
    // anymore (for example rotating the phone from portrait to landscape and
    // unloking space for the desktop menu instead of the mobile one)
    const handleResize = () => {
      if (buttonRef.current && getComputedStyle(buttonRef.current).display === "none") {
        setOpenAndResetAnimation(false);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Lines of the button are always white when open, otherwise use the
  // "hamburgerWhite" prop; also animate the color as it may change
  // opening/closing the menu
  const linesColor = twJoin(
    "transition-colors",
    open === true || hamburgerWhite ? "bg-white" : "bg-dark"
  );

  // The animation of the button introduces some accessibility challenges:
  // 1) we want to keep the button visible as it animates while the menu opens:
  //    solved tuning the z-index of the button, the menu modal and the top bar
  // 2) we want the same button to be able to both open and close the modal:
  //    this is difficult as the button is outside the modal, so HeadlessUI
  //    disables it when the modal is open; we solved it adding a second
  //    transparent button inside the modal, placed in the same position of the
  //    animated but disabled one, so that it makes it look like the original
  //    button can be keyboard focused and clicked. This is why we save size and
  //    position of the button in a var, so it can be reused for the second
  //    clone button.
  const buttonSizeAndPosition = "fixed flex w-12 h-12 p-3 -ml-3 left-8 top-1";

  return (
    <>
      <button
        ref={buttonRef}
        aria-label="Menu"
        className={twMerge(buttonSizeAndPosition, "z-[22] sm:hidden")}
        onClick={() => setOpenAndAnimate(true)}
      >
        {/* Wrapper that hanldes the rotation part of the animation */}
        <span ref={rotationWrapperRef} className="flex w-full h-full relative">
          {/* Lines of the hamburger */}
          <span
            ref={line1Ref}
            className={twMerge(
              "h-0.5 -mt-px w-full flex absolute top-1/2 translate-y-1.5",
              linesColor
            )}
          />
          <span
            ref={line2Ref}
            className={twMerge(
              "h-0.5 -mt-px w-full flex absolute top-1/2 -translate-y-1.5",
              linesColor
            )}
          />
        </span>
      </button>

      {/* Menu modal */}
      <Transition show={open}>
        <Dialog onClose={() => setOpenAndAnimate(false)}>
          <Transition.Child
            enter="transition-transform duration-250 ease-[ease]"
            enterFrom="transform -translate-x-full"
            enterTo="transform translate-x-0"
            leave="transition-transform duration-250 ease-[ease]"
            leaveFrom="transform translate-x-0"
            leaveTo="transform -translate-x-full"
            as={Fragment}
          >
            <Dialog.Panel
              as="nav"
              className="fixed top-0 left-0 h-screen w-[calc(100vw-1rem)] max-w-xs bg-dark z-[21]"
            >
              <ul className="p-0 pt-20">
                {navigationMobile.map((item, index) => (
                  <li
                    key={item.name}
                    className={`before:content-none px-0 py-3 flex items-center mt-0 animate-[fade-in_250ms_ease_both]`}
                    style={{ animationDelay: `${175 + 50 * index}ms` }}
                  >
                    {/* White line on the left to mark current page */}
                    <span
                      className={twJoin(
                        "w-1 h-9 mr-7",
                        router.pathname === item.href && "bg-white"
                      )}
                    />
                    <TransitionLink
                      href={item.href}
                      aria-current={router.pathname === item.href ? "page" : undefined}
                      className={
                        "text-3xl text-white transition-opacity ease-[ease] duration-250 override-hover hover:opacity-75"
                      }
                    >
                      {item.name}
                    </TransitionLink>
                  </li>
                ))}
              </ul>
            </Dialog.Panel>
          </Transition.Child>
          {/* Transparent button that overlaps the hamburger button and allows
              the modal to be closed; added after the other links so it's last
              in the tab order */}
          <button
            aria-label="Close Menu"
            className={twMerge(buttonSizeAndPosition, "z-[23]")}
            onClick={() => setOpenAndAnimate(false)}
          />
        </Dialog>
      </Transition>
    </>
  );
};

/**
 * The navbar can have three themes - light (default), dark & clear.
 *  dark & clear are very similar in the sense that they both need child elements to be white/light coloured,
 * so they both use Tailwind's dark mode utility, only that clear mode has no background when the scroll position is still at the top.
 *
 *  The clear navbar is always clear on the homepage,
 *  regardless of scroll position, but on other pages, it behaves like the default light theme.
 *
 * The classes would've been much cleaner if newly injected classes overrode old ones,
 * instead they all have to be removed based on the nav theme
 */
interface NavProps {
  dark?: boolean;
  clear?: boolean;
  home?: boolean; // the navbar should always be clear on the homepage
}

const Nav = ({ dark, clear, home }: NavProps) => {
  //  resize navbar height on scroll
  const [scrolledToTop, setScrolledToTop] = useState(true);
  const router = useRouter();

  // for clear navigation used on other pages BESIDES the home page
  const clearNavScrolledToTop = clear && !home && scrolledToTop;
  const clearNavScrolledFromTop = clear && !home && !scrolledToTop;

  useEffect(() => {
    const onScroll = () => setScrolledToTop(window.scrollY < 1);
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <header>
      <nav>
        {/* The hamburger button can't be a child of the top bar because
            it wouldn't be possible to position the menu sidebar between the 2,
            (above the top bar and below the hamburger) */}
        <HamburgerButtonAndMenu hamburgerWhite={home || clearNavScrolledToTop || false} />

        <div
          className={twMerge(
            "fixed w-full left-0 top-0 z-20 flex justify-center",
            (dark || clear) && "dark"
          )}
        >
          <div
            className={twMerge(
              "w-full transition-all duration-100",
              !clear && "bg-white dark:bg-black",
              clear && home && "bg-transparent",
              clearNavScrolledToTop && "bg-transparent",
              clearNavScrolledFromTop && "bg-white"
            )}
          >
            <div
              className={twMerge(
                "transition-all",
                scrolledToTop ? "constrained" : "constrained-nav"
              )}
            >
              <div
                className={twMerge(
                  "w-full flex justify-center items-center gap-6 transition-all py-1 sm:py-3",
                  scrolledToTop && "sm:pt-10"
                )}
              >
                {/* Logo */}
                <TransitionLink
                  href="/"
                  passHref
                  ariaLabel="Homepage"
                  className="flex flex-shrink-0"
                >
                  <Pump
                    className={twMerge(
                      "w-12 h-12 p-2 sm:-ml-2 transition-[fill]",
                      clearNavScrolledFromTop
                        ? "dark:fill-dark hover:dark:fill-current"
                        : "hover:fill-brand-700 dark:fill-white"
                    )}
                  />
                </TransitionLink>

                {/* Right side */}
                <ul className="hidden sm:flex items-center ml-auto gap-6 p-0">
                  {navigation.map((item) => (
                    <li key={item.name} className="before:content-none p-0 m-0">
                      <TransitionLink
                        href={item.href}
                        aria-current={router.pathname === item.href ? "page" : undefined}
                        className={twMerge(
                          "border-b-2 py-2 text-dark transition-all duration-100 ease-linear hover:border-brand-700",
                          // Active page check
                          router.pathname === item.href
                            ? "border-brand-700 text-brand-700 dark:opacity-100"
                            : "border-transparent dark:hover:opacity-100",
                          dark && "dark:border-transparent dark:text-light dark:opacity-65", // specifically for the dark nav
                          clear && home && "dark:text-light dark:hover:opacity-80", // specifically for home page
                          clearNavScrolledToTop && "dark:text-light dark:opacity-100",
                          clearNavScrolledFromTop &&
                            "dark:text-dark dark:opacity-100 hover:dark:text-brand-700"
                        )}
                      >
                        {item.name}
                      </TransitionLink>
                    </li>
                  ))}
                  <li className="before:content-none p-0 m-0">
                    {/* Contact Button */}
                    <TransitionLink
                      href="/contact"
                      passHref
                      className={twMerge(
                        "block px-4 py-2 text-sm font-normal capitalize hover:bg-brand-700 hover:text-white",
                        !clear && "bg-brand-500 text-light",
                        clear && "bg-light"
                      )}
                    >
                      Contact
                    </TransitionLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};
export default Nav;
